import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class PlanService {
  private readonly apiPrefix = '/client/plans';

  constructor(
    private api: ApiService,
  ) {
  }

  getWeeks(): Observable<any> {
    return this.api.get(`${this.apiPrefix}/weeks`);
  }

  getWeekItems(weekId: number): Observable<any> {
    return this.api.get(`${this.apiPrefix}/weeks/${weekId}/items`);
  }

  assignWeekItem(weekId: number, itemId: number, data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/weeks/${weekId}/items/${itemId}/assign`, data);
  }

  unassignWeekItem(weekId: number, itemId: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/weeks/${weekId}/items/${itemId}/assign`);
  }

  completeWeekItem(weekId: number, itemId: number): Observable<any> {
    return this.api.post(`${this.apiPrefix}/weeks/${weekId}/items/${itemId}/complete`, {});
  }

  uncompleteWeekItem(weekId: number, itemId: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/weeks/${weekId}/items/${itemId}/complete`);
  }

  transferWeekItem(weekId: number, itemId: number, data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/weeks/${weekId}/items/${itemId}/transfer`, data);
  }

  initPlan(): Observable<any> {
    return this.api.post(`${this.apiPrefix}/init`, {});
  }

  getActiveWeek(): Observable<any> {
    return this.api.get(`${this.apiPrefix}/active-week`);
  }

  createWeekItem(weekId: number, data: object) {
    return this.api.post(`${this.apiPrefix}/weeks/${weekId}/items`, data);
  }

  deleteWeekItem(itemId: number) {
    return this.api.delete(`${this.apiPrefix}/week-items/${itemId}`);
  }

  copyWeekItem(itemId: number, data: object) {
    return this.api.post(`${this.apiPrefix}/week-items/${itemId}/copy`, data);
  }

  searchWeekItem(filter: { lessonId?: number, lessonTopicId?: number }) {
    return this.api.get(`${this.apiPrefix}/week-items/search?`, Object.entries(filter).map(([k, value]) => `filter[${k}]=${value}`).join('&'));
  }

  changeActiveWeek(targetWeekId: number) {
    return this.api.post(`${this.apiPrefix}/active-week/shift/${targetWeekId}`, {});
  }

  getActiveWeekChangeHistory() {
    return this.api.get(`${this.apiPrefix}/active-week/shift`);
  }

  rollbackChangeActiveWeek() {
    return this.api.delete(`${this.apiPrefix}/active-week/shift`);
  }
}
